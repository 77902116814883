import MGalleryItem from './m-gallery__item';

class MGallery {
  constructor(element) {
    this.element = element;
    this.scrollContainerElement = element.querySelector('.m-gallery__scroll-container');
    this.scrollingElement = this.scrollContainerElement.querySelector(':scope > ul');
    this.buttonShowNextElement = element.querySelector('button[data-action="show next"]');
    this.buttonShowPreviousElement = element.querySelector('button[data-action="show previous"]');
    this.dotNavItemElements = element.querySelectorAll('.m-gallery__dot-nav button');
    const itemElements = this.scrollingElement.querySelectorAll(':scope > li');
    this.items = [...itemElements].map((itemElement, index) => new MGalleryItem(
      itemElement,
      this.scrollingElement,
      this.dotNavItemElements[index],
    ));

    let scrollXStart = null;
    let dragXStart = null;
    let dragX = null;
    let dragXDirection = null;
    let isDragging = null;
    let scrollSnapTimeout = null;

    // Event Listeners
    this.items.forEach((item) => {
      item.addEventListener('activate', () => {
        this.updateHeight();
        this.updateButtons();
      });
    });
    this.scrollContainerElement.addEventListener('mousedown', (event) => {
      event.preventDefault();
      this.scrollingElement.classList.add('-dragging');
      this.scrollingElement.classList.add('-no-scroll-snap');
      scrollXStart = this.scrollingElement.scrollLeft;
      dragXStart = event.clientX;
      dragX = 0;
      dragXDirection = null;
      isDragging = true;
      clearTimeout(scrollSnapTimeout);
    });
    window.addEventListener('mousemove', (event) => {
      if (isDragging) {
        event.preventDefault();
        const newDragX = (dragXStart - event.clientX);
        dragXDirection = (newDragX > dragX) ? 1 : dragXDirection;
        dragXDirection = (newDragX < dragX) ? -1 : dragXDirection;
        dragX = newDragX;
        this.scrollingElement.scrollLeft = scrollXStart + dragX;
      }
    });
    window.addEventListener('mouseup', () => {
      if (isDragging) {
        const widthOfItem = this.scrollingElement.scrollWidth / this.items.length;
        let newScrollLeft = scrollXStart;
        if ((dragX > 30 && dragXDirection === 1) || dragX > widthOfItem * 0.5) {
          newScrollLeft = (scrollXStart + Math.max(dragX, widthOfItem));
        } else if ((dragX < -30 && dragXDirection === -1) || dragX < widthOfItem * -0.5) {
          newScrollLeft = (scrollXStart + Math.min(dragX, widthOfItem * -1));
        }
        newScrollLeft = Math.round(newScrollLeft / widthOfItem) * widthOfItem;
        this.scrollingElement.scroll({
          left: newScrollLeft,
          behavior: 'smooth',
        });
      }
      isDragging = false;
      this.scrollingElement.classList.remove('-dragging');
      scrollSnapTimeout = setTimeout(() => {
        if (!isDragging) {
          this.scrollingElement.classList.remove('-no-scroll-snap');
        }
      }, 600);
    });
    window.addEventListener('resize', this.updateHeight.bind(this));
    this.buttonShowNextElement.addEventListener('click', this.showNextItem.bind(this));
    this.buttonShowPreviousElement.addEventListener('click', this.showPreviousItem.bind(this));

    document.addEventListener('keydown', (event) => {
      if (this.isElementInView) {
        if (event.key === 'ArrowLeft') {
          this.showPreviousItem();
        } else if (event.key === 'ArrowRight') {
          this.showNextItem();
        }
      }
    });

    // init
    this.updateHeight();
    this.updateButtons();
  }

  get activeItem() {
    return this.items.find((item) => item.isActive);
  }

  get activeIndex() {
    return this.items.findIndex((item) => item.isActive);
  }

  get isElementInView() {
    const rect = this.element.getBoundingClientRect();
    return rect.top < window.innerHeight && rect.bottom >= 0;
  }

  showNextItem() {
    const newActiveIndex = this.activeIndex + 1;
    if (this.items[newActiveIndex] ?? false) {
      this.buttonShowNextElement.animate({
        opacity: [0.5],
      }, {
        duration: 100,
      });
      this.items[newActiveIndex].show();
    }
  }

  showPreviousItem() {
    const newActiveIndex = this.activeIndex - 1;
    if (this.items[newActiveIndex] ?? false) {
      this.buttonShowNextElement.animate({
        opacity: [0.5],
      }, {
        duration: 100,
      });
      this.items[newActiveIndex].show();
    }
  }

  updateHeight() {
    const activeItemHeight = this.activeItem?.element.offsetHeight;
    if (activeItemHeight) {
      Object.assign(this.scrollContainerElement.style, {
        height: `${activeItemHeight}px`,
      });
    }
  }

  updateButtons() {
    const indexActive = this.items.findIndex((item) => item.isActive);
    this.buttonShowPreviousElement.toggleAttribute('disabled', !(this.items[indexActive - 1] ?? false));
    this.buttonShowNextElement.toggleAttribute('disabled', !(this.items[indexActive + 1] ?? false));
  }
}

document.querySelectorAll('.m-gallery').forEach((element) => new MGallery(element));
