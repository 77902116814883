class ALabel {
  constructor(element) {
    const inputElement = element.querySelector('input');

    const toggleEmptyState = () => {
      element.classList.toggle('-not-empty', inputElement.value.length > 0);
    };

    inputElement.addEventListener('input', toggleEmptyState);
    toggleEmptyState();
  }
}

document.querySelectorAll('.a-label').forEach((element) => new ALabel(element));
