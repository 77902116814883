class MFormNewsletter {
  constructor(element) {
    this.element = element;
    this.action = element.action;
    this.method = element.method;
    this.errorElement = element.querySelector('.a-label__error');
    this.buttonElement = element.querySelector('button');
    this.formElements = element.elements;
    this.labelElement = element.querySelector('.a-label');

    element.addEventListener('submit', async (event) => {
      event.preventDefault();
      this.buttonElement.classList.add('-loading');
      const result = await this.submit().catch((error) => {
        this.showErros(error);
      });
      this.buttonElement.classList.remove('-loading');
      if (result.status === 200) {
        this.success(result.message);
      } else {
        this.showError(result.message);
        this.enableForm();
      }
    });
  }

  clearErros() {
    Object.assign(this.errorElement, {
      innerText: null,
    });
  }

  showError(error) {
    Object.assign(this.errorElement, {
      innerHTML: error,
    });
  }

  enableForm() {
    const {
      formElements,
    } = this;

    Array.from(formElements).forEach((formElement) => {
      Object.assign(formElement, {
        disabled: false,
      });
    });
  }

  disableForm() {
    const {
      formElements,
    } = this;

    Array.from(formElements).forEach((formElement) => {
      Object.assign(formElement, {
        disabled: true,
      });
    });
  }

  submit() {
    const {
      action,
      element,
      method,
    } = this;
    const formData = new FormData(element);
    this.disableForm();
    return fetch(action, {
      method,
      body: formData,
    }).then((response) => response.json())
      .then((result) => {
        if (result.success === false) {
          this.enableForm();
        }
        return result;
      })
      .catch((error) => {
        this.enableForm();
        return error;
      });
  }

  success(message = 'Success') {
    const {
      element,
      labelElement,
      buttonElement,
    } = this;
    const successElement = element.querySelector('.m-form__success');
    successElement.hidden = false;
    buttonElement.hidden = true;
    labelElement.hidden = true;
    successElement.querySelector('span').innerHTML = message;
    element.classList.add('-success');
  }
}

document.querySelectorAll('.m-form-newsletter').forEach((element) => new MFormNewsletter(element));
