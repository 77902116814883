class MGalleryItem extends EventTarget {
  constructor(element, scrollingElement, dotElement) {
    super();
    this.element = element;
    this.dotElement = dotElement;
    this.scrollingElement = scrollingElement;
    this.activateEvent = new CustomEvent('activate');

    const onScroll = () => {
      if (this.isElementInView) {
        this.activate();
      } else {
        this.deactivate();
      }
    };

    // Event Listeners
    this.scrollingElement.addEventListener('scroll', onScroll, {
      passive: true,
    });
    this.dotElement.addEventListener('click', this.show.bind(this));

    // Inits
    onScroll();
  }

  show() {
    this.scrollingElement.scroll({
      left: this.element.offsetLeft,
      behavior: 'smooth',
    });
  }

  activate() {
    this.element.removeAttribute('inert');
    this.dotElement.setAttribute('aria-pressed', 'true');
    this.dispatchEvent(this.activateEvent);
  }

  deactivate() {
    this.element.setAttribute('inert', '');
    this.dotElement.setAttribute('aria-pressed', 'false');
  }

  get isActive() {
    return !this.element.hasAttribute('inert');
  }

  get isElementInView() {
    const rect = this.element.getBoundingClientRect();
    const parentRect = this.scrollingElement.getBoundingClientRect();
    return (
      rect.left >= parentRect.left - rect.width * 0.5
      && rect.right <= parentRect.right + rect.width * 0.5
    );
  }
}

export default MGalleryItem;
