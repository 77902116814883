class MVideo {
  constructor(element) {
    this.element = element;
    this.cookieBannerElement = element.querySelector('.m-video__cookie-banner');
    this.iframeElement = element.querySelector('iframe');
    const rememberCheckboxElement = element.querySelector('input[name="remember"]');
    const buttonElement = element.querySelector('button');
    const provider = buttonElement.value;

    function showAllVideos() {
      document.mVideos.forEach((mVideo) => {
        mVideo.show();
      });
    }

    if (localStorage.getItem(`${provider}_content`) === 'true') {
      this.show(false);
    }

    element.addEventListener('submit', (event) => {
      event.preventDefault();
      if (rememberCheckboxElement.checked) {
        localStorage.setItem(`${provider}_content`, 'true');
        showAllVideos();
      }
      this.show();
    });
  }

  show(animate = true) {
    const {
      element,
      iframeElement,
      cookieBannerElement,
    } = this;
    element.classList.add('-active');
    iframeElement.removeAttribute('hidden');
    iframeElement.setAttribute('src', iframeElement.dataset.src);
    if (animate === true) {
      element.classList.add('-animate');
      const fadeOutAnimation = cookieBannerElement.animate(
        {
          opacity: 0,
        },
        {
          delay: 100,
          duration: 250,
        },
      );
      fadeOutAnimation.addEventListener('finish', () => {
        cookieBannerElement.setAttribute('hidden', '');
      });
    } else {
      cookieBannerElement.setAttribute('hidden', '');
    }
    // eslint-disable-next-line no-underscore-dangle
    window._paq?.push(['trackEvent', 'Video', 'Show', iframeElement.dataset.src]);
  }
}

document.mVideos = [];
document.querySelectorAll('.m-video').forEach((element) => {
  document.mVideos.push(new MVideo(element));
});
