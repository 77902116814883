class AButttonHamburger {
  constructor(element) {
    const oNavElement = document.querySelector('.o-nav');

    element.addEventListener('click', () => {
      oNavElement.classList.toggle('-open');
    });
    document.addEventListener('keydown', (event) => {
      if (event.key === 'Escape' || event.keyCode === 27) {
        oNavElement.classList.remove('-open');
      }
    });
  }
}

document.querySelectorAll('.a-button-hamburger').forEach((element) => new AButttonHamburger(element));
