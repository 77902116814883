class OArtistsContainer {
  constructor(element) {
    this.element = element;
    this.navElement = element.querySelector('.o-artists-container__nav');
    this.buttonElements = this.navElement.querySelectorAll('div > button');
    this.scrollContainerElement = element.querySelector('.o-artists-container__scroll-container');
    this.contentElement = element.querySelector('.o-artists-container__content');
    this.contentChildrenElements = this.contentElement.children;
    this.oArtistsElement = element.querySelectorAll('.o-artists');
    this.buttonPreviousElement = element.querySelector('[data-action="previous"]');
    this.buttonNextElement = element.querySelector('[data-action="next"]');

    this.activeIndex = 0;

    this.buttonPreviousElement?.addEventListener('click', () => {
      this.showPrevious();
    });
    this.buttonNextElement?.addEventListener('click', () => {
      this.showNext();
    });
    this.buttonElements.forEach((buttonElement, index) => {
      buttonElement.addEventListener('click', () => {
        this.activeIndex = index;
        this.setActive();
      });
    });

    window.addEventListener('resize', this.setActive.bind(this), {
      passive: true,
    });
    document.addEventListener('keydown', (event) => {
      if (event.key === 'ArrowLeft') {
        this.showPrevious();
      } else if (event.key === 'ArrowRight') {
        this.showNext();
      }
    });

    this.setActive();
  }

  showPrevious() {
    if (this.oArtistsElement[this.activeIndex - 1]) {
      this.activeIndex -= 1;
      this.setActive();
    }
  }

  showNext() {
    if (this.oArtistsElement[this.activeIndex + 1]) {
      this.activeIndex += 1;
      this.setActive();
    }
  }

  updateButtons() {
    const { activeIndex } = this;
    this.buttonPreviousElement.toggleAttribute('disabled', !this.oArtistsElement[activeIndex - 1]);
    this.buttonNextElement.toggleAttribute('disabled', !this.oArtistsElement[activeIndex + 1]);
  }

  setActive() {
    const { activeIndex } = this;
    if (this.oArtistsElement[activeIndex]) {
      this.buttonElements.forEach((buttonElement, index) => {
        if (index === activeIndex) {
          buttonElement.setAttribute('aria-pressed', 'true');
          this.navElement.scroll({
            left: buttonElement.offsetLeft - buttonElement.offsetWidth * 0.5,
            behavior: 'smooth',
          });
        } else {
          buttonElement.setAttribute('aria-pressed', 'false');
        }

        const contentItemElement = this.contentChildrenElements[index];
        contentItemElement.toggleAttribute('inert', index !== activeIndex);
        Object.assign(contentItemElement.style, {
          translate: `${100 * activeIndex * -1}% 0`,
        });
        Object.assign(contentItemElement.style, {
          height: `${this.oArtistsElement[activeIndex].offsetHeight}px`,
        });
      });
      Object.assign(this.contentElement.style, {
        height: `${this.oArtistsElement[activeIndex].offsetHeight}px`,
      });
    }
    this.updateButtons();
  }
}

document.querySelectorAll('.o-artists-container').forEach((element) => new OArtistsContainer(element));
