class OArtistsSection {
  constructor(element, oArtistsElement) {
    this.element = element;
    this.isPartiallyVisible = false;
    this.letter = element.getAttribute('aria-label');
    this.alphabetLinkElement = [...oArtistsElement
      .querySelectorAll('.o-artists__alphabet a')]
      .find((alphabetLinkElement) => alphabetLinkElement.innerText.toLowerCase() === this.letter);

    this.alphabetLinkElement.addEventListener('click', (event) => {
      event.preventDefault();
      const rect = element.getBoundingClientRect();
      const scrollMarginBlockStart = parseFloat(getComputedStyle(element).scrollMarginBlockStart);
      const scrollTop = rect.top + document.scrollingElement.scrollTop - scrollMarginBlockStart;
      document.scrollingElement.scroll({
        top: scrollTop,
      });
    });
  }

  updateAlphabetLinkElement() {
    if (this.alphabetLinkElement) {
      this.alphabetLinkElement.classList.toggle('-active', this.isPartiallyVisible);
    }
  }

  isInViewport() {
    const rect = this.element.getBoundingClientRect();
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    this.isPartiallyVisible = (
      rect.top < windowHeight
      && rect.bottom > 48
      && rect.left < windowWidth
      && rect.right > 0
    );
    this.element.dataset.isPartiallyVisible = this.isPartiallyVisible;
    this.updateAlphabetLinkElement();
    return this.isPartiallyVisible;
  }
}

class OArtists {
  constructor(element) {
    this.sections = [];
    this.alphabetScrollingElement = element.querySelector('.o-artists__alphabet > div');

    element.querySelectorAll('.o-artists__section').forEach((sectionElement) => {
      this.sections.push(new OArtistsSection(sectionElement, element));
    });

    window.addEventListener('scroll', () => {
      let firstSectionInViewport = null;
      this.sections.forEach((section) => {
        if (section.isInViewport()) {
          if (firstSectionInViewport === null) {
            firstSectionInViewport = section;
          }
        }
      });
      if (firstSectionInViewport !== null && firstSectionInViewport.alphabetLinkElement) {
        const scrollLeft = firstSectionInViewport.alphabetLinkElement.offsetLeft - 20;
        this.alphabetScrollingElement.scroll({
          left: scrollLeft,
          behavior: 'smooth',
        });
      }
    }, {
      passive: true,
    });
  }
}

document.querySelectorAll('.o-artists').forEach((element) => new OArtists(element));
