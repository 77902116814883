import './components/a-label';
import './components/a-button-hamburger';
import './components/m-form-newsletter';
import './components/m-gallery';
import './components/m-video';
import './components/o-artists';
import './components/o-artists-container';

// detect apple devices
const isAppleDevice = /Mac|iPad|iPhone|iPod/.test(navigator.userAgent);
if (isAppleDevice) {
  document.body.classList.add('-apple');
} else {
  document.body.classList.add('-non-apple');
}
